<template>
  <div class="onboarding">
    <div class="af-header">
      <router-link to="/user/profile" class="logo-router">
        <img
          class="instacash-logo cursor-pointer"
          :src="
            isBrand(CUSTOM_APPEARANCE.MBH)
              ? require('@/assets/images/mbh-logo.svg')
              : require('@/assets/images/instacash-logo-black.svg')
          "
          alt="Logo"
        />
      </router-link>
    </div>

    <slot />
  </div>
</template>

<script>
import { isBrand } from "@/utils/util";
import { CUSTOM_APPEARANCE } from "@/utils/const";

export default {
  name: "AppLayoutOnboarding",
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
  },
  methods: { isBrand },
};
</script>

<style scoped lang="scss">
.onboarding {
  background: #f5f7fb;
  height: 100vh;

  .af-header {
    display: none;
    justify-content: center;
    align-items: center;
    height: 60px;
    background: white;
    margin: 0;

    .instacash-logo {
      height: 22px;
    }
  }
}

@media only screen and (min-width: 769px) {
  .af-header {
    display: flex !important;
  }

  .onboarding {
    background: url("../assets/images/mbh_desktop_bg.png") no-repeat center;
    background-size: cover;
  }
}
</style>
